export const teamList = [
  {
    job: "C.D.O",
    name: "Mariana Rojas",
    photo: require("../../../assets/team/mariana.png"),
    linkedin: "https://www.linkedin.com/in/mariana-rojas-538b8b60",
  },
  // {
  //   job: "C.M.O.",
  //   name: "Mariela Rojas",
  //   photo: require("../../../assets/team/mariala.png"),
  //   linkedin:
  //     "https://www.linkedin.com/in/mariela-carolina-rojas-m%C3%A9ndez-93788a95/",
  // },
  // {
  //   job: "C.C.O",
  //   name: "Ahmed",
  //   photo: require("../../../assets/team/ahmed.png"),
  //   linkedin: "https://www.linkedin.com/in/iamahmadk/",
  // },
  {
    job: "Media Producer",
    name: "Javier García",
    photo: require("../../../assets/team/javiar.png"),
    linkedin: "https://www.linkedin.com/in/javier-garcia-576bb6276",
  },
  {
    job: "Back End Developer",
    name: "Hassan Khan",
    photo: require("../../../assets/team/hassan.png"),
    linkedin: "https://www.linkedin.com/in/hassan-khan-224653222/",
  },
  {
    job: "Front End Developer",
    name: "Abdul Fatir",
    photo: require("../../../assets/team/fatir.png"),
    linkedin: "https://www.linkedin.com/in/abdulfatiransari",
  },
];

export const newCoreTeam = [
  {
    job: "C.E.O",
    name: "Juan Vivas",
    photo: require("../../../assets/team/juan.png"),
    linkedin: "https://www.linkedin.com/in/juanvivaslovera/",
  },
  {
    job: "C.O.O",
    name: "Stefan Zanev",
    photo: require("../../../assets/team/stefan.png"),
    linkedin: "https://www.linkedin.com/in/stefan-zanev-0a2b8324b",
  },
  {
    job: "C.D.O",
    name: "Mariana Rojas",
    photo: require("../../../assets/team/mariana.png"),
    linkedin: "https://www.linkedin.com/in/mariana-rojas-538b8b60",
  },
  // {
  //   job: "C.M.O.",
  //   name: "Mariela Rojas",
  //   photo: require("../../../assets/team/mariala.png"),
  //   linkedin:
  //     "https://www.linkedin.com/in/mariela-carolina-rojas-m%C3%A9ndez-93788a95/",
  // },
  // {
  //   job: "C.C.O",
  //   name: "Muhammad Ahmed",
  //   photo: require("../../../assets/team/ahmed.png"),
  //   linkedin: "https://www.linkedin.com/in/iamahmadk/",
  // },
];

export const marketingTeam = [
  // {
  //   job: "C.M.O.",
  //   name: "Mariela Rojas",
  //   photo: require("../../../assets/team/mariala.png"),
  //   linkedin:
  //     "https://www.linkedin.com/in/mariela-carolina-rojas-m%C3%A9ndez-93788a95/",
  // },
  {
    job: "Media Producer",
    name: "Javier García",
    photo: require("../../../assets/team/javiar.png"),
    linkedin: "https://www.linkedin.com/in/javier-garcia-576bb6276",
  },
  // {
  //   job: "C.C.O",
  //   name: "Muhammad Ahmed",
  //   photo: require("../../../assets/team/ahmed.png"),
  //   linkedin: "https://www.linkedin.com/in/iamahmadk/",
  // },
  {
    job: "C.D.O",
    name: "Mariana Rojas",
    photo: require("../../../assets/team/mariana.png"),
    linkedin: "https://www.linkedin.com/in/mariana-rojas-538b8b60",
  },
];

export const devTeam = [  
  {
    job: "Software Engineer",
    name: "Hassan Khan",
    photo: require("../../../assets/team/hassan.png"),
    linkedin: "https://www.linkedin.com/in/hassan-khan-224653222/",
  },
];

export const coreteamList = [
  {
    job: "C.E.O",
    name: "Juan Vivas",
    photo: require("../../../assets/team/juan.png"),
    linkedin: "https://www.linkedin.com/in/juanvivaslovera/",
  },
  {
    job: "C.O.O",
    name: "Stefan Zanev",
    photo: require("../../../assets/team/stefan.png"),
    linkedin: "https://www.linkedin.com/in/stefan-zanev-0a2b8324b",
  },
];
